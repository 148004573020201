/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/430e5d24428db28582955b079d7c74af3a8619e2/src/Flowingly.Shared.Angular/flowingly.components/smartselect/smart.select.component.js?at=master
 */
(function () {
  'use strict';

  angular
    .module('flowingly.components')
    ///
    /// A container component for smart select components. Handles communication between them.
    /// <smart-select-search> -
    /// <smart-select-list> -
    ///
    /// See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Smart+Select+Component
    ///
    .component('smartSelect', {
      bindings: {
        //list of items the user can search from (will populate LHS of smart-select-list)
        searchGroup: '<',
        selectedList: '<',
        filterBy: '<?',
        searchGroupDisabled: '<',
        readOnly: '<?',
        headerText: '@',
        infoText: '@',
        availableUsers: '<?',
        availableGroups: '<?',
        expandGroupMembersFilter: '&',
        isEmailRecipients: '<?',
        availableEmailFields: '<?',
        uniqueId: '@',
        showTitle: '<?',
        showUser: '<?',
        showTeam: '<?',
        showCustomEmailInput: '<?'
      },
      controller: [
        'lodashService',
        'flowinglyConstants',
        function (lodashService, flowinglyConstants) {
          var $ctrl = this;

          $ctrl.updateList = updateList;

          $ctrl.$onInit = function () {
            //list of items the user can search from (will populate LHS of smart-select-list)
            $ctrl.searchList = [];
            //currently selected search group (0=users, 2 = groups)
            $ctrl.searchGroup =
              $ctrl.searchGroup || flowinglyConstants.searchEntityType.USER;
            //list of potential items for the search list (returned from server)
            $ctrl.resultsList = [];

            if (!$ctrl.selectedList) {
              $ctrl.selectedList = [];
            }

            $ctrl.isSearchGroupDisabled = !!$ctrl.searchGroupDisabled;
            $ctrl.readOnly = $ctrl.readOnly || false; // default to false
            $ctrl.filterBy = $ctrl.filterBy || '0'; // default to false
            $ctrl.showTitle = $ctrl.showTitle || true;

            if (!$ctrl.showUser) {
              $ctrl.showUser = true;
            }
            if (!$ctrl.showTeam) {
              $ctrl.showTeam = true;
            }
          };

          $ctrl.$onChanges = (changes) => {
            if (changes.selectedList && !changes.selectedList.isFirstChange()) {
              if ($ctrl.searchList && $ctrl.searchList.length > 0) {
                // refresh the search list in case of selected list changed
                updateList($ctrl.searchList, $ctrl.searchGroup);
              }
            }
          };

          function updateList(results, searchGroup) {
            ///
            /// The user has changed the search filter (search group - user/group)
            /// Update the search list (will appear on LHS smart-select-list)
            /// But first, remove any items that have already been selected (are in RHS of smart-select-list)
            ///
            switch (searchGroup) {
              case flowinglyConstants.searchEntityType.USER:
                lodashService.forEach(results, function (r) {
                  r.displayName = r.displayName || r.fullName;
                  r.searchEntityType = flowinglyConstants.searchEntityType.USER;
                });
                break;

              case flowinglyConstants.searchEntityType.GROUP:
                lodashService.forEach(results, function (r) {
                  r.displayName = r.displayName || r.name;
                  r.searchEntityType =
                    flowinglyConstants.searchEntityType.GROUP;
                });
                break;

              case flowinglyConstants.searchEntityType.APPROVER:
                lodashService.forEach(results, function (r) {
                  r.displayName = r.displayName || r.fullName;
                  r.searchEntityType = flowinglyConstants.searchEntityType.USER;
                });
                break;

              case flowinglyConstants.searchEntityType.STEP_FORM_FIELD:
                lodashService.forEach(results, function (r) {
                  r.displayName = r.displayName;
                  r.searchEntityType =
                    flowinglyConstants.searchEntityType.STEP_FORM_FIELD;
                });
                break;

              default:
                console.error('unknown filter group');
            }

            //remove any that are already selected
            $ctrl.searchList = removeDuplicates(results, $ctrl.selectedList);
          }

          function removeDuplicates(arrayOne, arrayTwo) {
            //remove any item from arrayOne that is also in arrayTwo.
            return lodashService.differenceWith(
              arrayOne,
              arrayTwo,
              function (a, b) {
                return a.id === b.id;
              }
            );
          }
        }
      ],
      template: `
                <div class="smart-select">
                    <smart-select-search
                        ng-show="!$ctrl.isSearchGroupDisabled"
                        search-group="$ctrl.searchGroup"
                        unique-id="{{$ctrl.uniqueId}}"
                        filter-by="$ctrl.filterBy"
                        available-users="$ctrl.availableUsers"
                        available-groups="$ctrl.availableGroups"
                        on-results-list-updated="$ctrl.updateList(results, searchGroup)"
                        results-list="$ctrl.resultsList"
                        is-email-recipients="$ctrl.isEmailRecipients"
                        available-email-fields="$ctrl.availableEmailFields"
                        show-title="$ctrl.showTitle"
                        show-user="$ctrl.showUser"
                        show-team="$ctrl.showTeam"
                        show-custom-email-input="$ctrl.showCustomEmailInput">
                    </smart-select-search>

                    <smart-select-list
                        read-only="$ctrl.readOnly"
                        search-list="$ctrl.searchList"
                        search-group="$ctrl.searchGroup"
                        selected-list="$ctrl.selectedList"
                        header-text="{{$ctrl.headerText}}"
                        unique-id="{{$ctrl.uniqueId}}"
                        filter-by="$ctrl.filterBy"
                        info-text="{{$ctrl.infoText}}"
                        on-selected-list-updated="$ctrl.updateList(results, searchGroup)"
                        expand-group-members-filter="$ctrl.expandGroupMembersFilter({user: user})">
                    </smart-select-list>
                </div>
            `
    });
})();
