export enum ModelerActionEvent {
  PUBLISH = 'publish',
  PUBLISH_AS_WORKFLOW_COMPONENT = 'publishAsWorkflowComponent',
  PUBLISH_AS_PROCESS_MAP = 'publishAsProcessMap',
  PUBLISH_AS_PROCESS_MAP_COMPONENT = 'publishAsProcessMapComponent',
  PRINT_STEPS = 'printSteps',
  EXPORT_MAP = 'exportMap',
  EXPORT = 'export',
  EXPORT_XML = 'exportXML',
  IMPORT = 'import',
  EDIT = 'edit',
  SAVE = 'save',
  TOGGLE_FEEDBACK_PANEL = 'toggleFeedbackPanel',
  VIEW_ERRORS = 'viewErrors'
}
