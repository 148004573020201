/**
 * @ngdoc service
 * @name commentApiService
 * @module flowingly.services
 *
 * @description A helper API service for comments
 *
 * ## Notes
 *
 * ###API
 * * addFlowComment - add a new flow comment optional with mentions
 * * getFlowComments - get flow comments
 * * searchMentions - search mentions by given search text
 *
 */

import angular from 'angular';
import { IComment } from '../interfaces/comment.interface';
import { ICommentMention } from '../interfaces/comment-mention.interface';
import { Services } from '../@types/services';
import { ActorType } from '../interfaces/actor-type.enum';

export class CommentApiService {
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: Services.APP_CONFIG,
    private flowinglyMentionService: Services.FlowinglyMentionService,
    private fileService: Services.FileService
  ) {}

  addFlowComment(
    commentTargetType: number,
    commentTargetId: string,
    comment: IComment,
    isGovernance = false
  ) {
    const url = `${this.APP_CONFIG.apiBaseUrl}comments/${commentTargetType}/${commentTargetId}?isGovernance=${isGovernance}`;

    return this.$http.post(url, comment);
  }

  getFlowComments(
    commentTargetType: number,
    commentTargetId: string,
    noSpinner = false,
    isRequestFromGovernance = false
  ) {
    const url = `${this.APP_CONFIG.apiBaseUrl}comments/${commentTargetType}/${commentTargetId}?isRequestFromGovernance=${isRequestFromGovernance}`;

    return this.$http.get<IComment[]>(url, { noSpinner }).then((response) => {
      const comments = this.flowinglyMentionService.transformRawComments(
        response.data
      );
      comments &&
        comments.forEach((comment) => {
          comment.files &&
            comment.files.forEach(
              (f) =>
                (f.downloadLink = this.fileService.getDownloadLink(f.fileId))
            );
        });

      return comments;
    });
  }

  async searchMentions(
    searchText: string,
    flowOwnerOnly: boolean,
    commentTargetId: string,
    noSpinner: boolean
  ) {
    flowOwnerOnly = flowOwnerOnly || false;
    const response = await this.$http.get<ICommentMention[]>(
      `${this.APP_CONFIG.apiBaseUrl}actors/search?searchText=${searchText}&flowOwnerOnly=${flowOwnerOnly}&commentTargetId=${commentTargetId}`,
      { noSpinner: noSpinner || false }
    );
    if (this.APP_CONFIG.disableEveryoneMentions && response.data) {
      const everyoneIndex = response.data.findIndex(
        (actor) =>
          actor.actorName === 'Everyone' && actor.actorTypeId === ActorType.Team
      );
      if (everyoneIndex > -1) {
        response.data.splice(everyoneIndex, 1);
      }
    }
    return response.data;
  }
}

angular
  .module('flowingly.services')
  .factory('commentApiService', [
    '$http',
    'APP_CONFIG',
    'flowinglyMentionService',
    'fileService',
    ($http, APP_CONFIG, flowinglyMentionService, fileService) =>
      new CommentApiService(
        $http,
        APP_CONFIG,
        flowinglyMentionService,
        fileService
      )
  ]);

export type CommentApiServiceType = InstanceType<typeof CommentApiService>;
