/**
 * @ngdoc service
 * @name permissionsService
 * @module flowingly.directives
 *
 * @description A service for checking user permissions
 *
 * ## Notes
 *
 * ###API
 * * check - check if used meets required permissions
 *
 * ###Usage
 *     Consumed by flow.if.permissions.directive directive.
 */

import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';

angular
  .module('flowingly.directives')
  .factory('permissionsService', ['sessionService', permissionsService]);

permissionsService.$inject = [];

function permissionsService(sessionService) {
  const service = {
    checkEnvironment: checkEnvironment,
    currentUserHasPermission: currentUserHasPermission,
    currentUserHasPermissions: currentUserHasPermissions,
    currentUserHasAnyOfPermissions: currentUserHasAnyOfPermissions
  };

  return service;

  /// Public API Methods /////////////////////////////////////////////////////////////

  function checkEnvironment(allowedEnvironments, currentEnvironment) {
    if (allowedEnvironments === undefined) {
      return true;
    }
    return allowedEnvironments.indexOf(currentEnvironment) >= 0;
  }

  function currentUserHasPermission(requiredPermission: FlowinglyPermissions) {
    return currentUserHasPermissions([requiredPermission]);
  }

  function currentUserHasPermissions(
    requiredPermissions: FlowinglyPermissions[]
  ) {
    if (requiredPermissions === undefined || requiredPermissions.length === 0) {
      return false;
    }
    const userPermissions = sessionService.getPermissions();
    if (!userPermissions) return false;
    return requiredPermissions.every((permission) => {
      return userPermissions.indexOf(permission) >= 0;
    });
  }

  function currentUserHasAnyOfPermissions(
    requiredPermissions: FlowinglyPermissions[]
  ) {
    if (requiredPermissions === undefined || requiredPermissions.length === 0) {
      return false;
    }
    const userPermissions = sessionService.getPermissions();
    return requiredPermissions.some((permission) => {
      return userPermissions.indexOf(permission) >= 0;
    });
  }
}

export type PermissionsServiceType = ReturnType<typeof permissionsService>;
